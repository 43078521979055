import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import "./HowIiWorksTab.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div className="image-box">
      {value === index && (
        <Box  >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}



export default function VerticalTabs() {
  
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="root">
      <Tabs
        orientation="vertical"
       // variant=""
       value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
       //className="tabs"
      >
        <Tab label="Connect cameras or upload visual data" {...a11yProps(0)}  className="tab-control"/>
        <Tab label="Create robust visual datasets" {...a11yProps(1)} className="tab-control" />
        <Tab label="Train highly accurate AI models" {...a11yProps(2)} className="tab-control" />
        <Tab label="Deploy AI applications on edge or cloud" {...a11yProps(3)}  className="tab-control"/>
        <Tab label="Review all data via centralized dashboards" {...a11yProps(4)} className="tab-control" />
        
      </Tabs>
      <TabPanel value={value} index={0} >
        <img src="..\Images/Connect-Cameras-or-Upload-Visual-Data.svg" alt="Connect-Cameras-or-Upload-Visual-Data" width="521" height="305"></img>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <img src="..\Images/Create-Robust-Datasets-for-Model-Training.svg" alt="Create-Robust-Datasets-for-Model-Training" width="550" height="321"></img>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <img src="..\Images/Train-and-Benchmark-Highly-Accurate-CV-Models.svg" alt="Train-and-Benchmark-Highly-Accurate-CV-Models" width="550" height="292"></img>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <img src="..\Images/Deploy-and-Continuously-Monitor-all-Endpoints-and-Devices.svg" alt="Deploy-and-Continuously-Monitor-all-Endpoints-and-Devices" width="550" height="auto"></img>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <img src="..\Images/Build-and-Configure-AI-Applications.svg" alt="Connect-Cameras-or-Upload-Visual-Data" width="551" height="303"></img>
      </TabPanel>
    </div>
  );
}