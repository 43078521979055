import React from "react";
import styled from "styled-components";
import { useSpring, animated, config } from "react-spring";
import Brand from "./Brand";
import BurgerMenu from "./BurgerMenu";
import CollapseMenu from "./CollapseMenu";
import { Link } from "react-router-dom";
import {NavLink} from 'react-router-dom'
const Navbar = props => {
  return (
    <>
    <div className="custom-container ">
      <NavBar >
        <FlexContainer>
          <Brand />
          <NavLinks >
            
            <li>
					<NavLink  to="/platform" className="text-blue-dark small-heading link-animation" >Platform</NavLink >
				</li>
				<li>
				
          <DropDownLi>
              <Link  className="text-blue-dark small-heading link-animation"    to="#"  onClick={props.handleNavbar}>
               Solutions<svg  viewBox="0 0 384 512"><path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/></svg>
              </Link >
              <DropDownContent>
               
                <ul>
              
                  <li>
					<NavLink  to="solutions/manufacturing" className="text-blue-dark small-heading link-animation" onClick={props.handleNavbar}>Manufacturing</NavLink >
				</li>
				<li>
					<NavLink  to="solutions/retail" className="text-blue-dark small-heading link-animation" onClick={props.handleNavbar}>Retail</NavLink >
				</li>
				<li>
					<NavLink  to="/solutions/logistics" className="text-blue-dark small-heading link-animation" onClick={props.handleNavbar}>Logistics</NavLink >
				</li>
				<li>
					<NavLink  to="solutions/securitysafety" className="text-blue-dark small-heading link-animation" onClick={props.handleNavbar}>Security & Safety</NavLink >
				</li>
		
                </ul>
              </DropDownContent>
            </DropDownLi>
        
        
        </li>
				<li>
					<NavLink  to="/about" className="text-blue-dark small-heading link-animation"  onClick={props.handleNavbar}>About Dori</NavLink >
				</li>
				<li>
					<NavLink  to="/careers" className="text-blue-dark small-heading link-animation"  onClick={props.handleNavbar}>Careers</NavLink >
				</li>
				{/*<li>
					<Link to="" className="text-blue-dark small-heading link-animation" >Resources</Link>
        </li>*/}
				<li>
					<NavLink  to="/contact" className="text-blue-dark small-heading link-animation"  onClick={props.handleNavbar}>Contact Us</NavLink >
				</li>
           
          </NavLinks>
          <BurgerWrapper>
            <BurgerMenu
              navbarState={props.navbarState}
              handleNavbar={props.handleNavbar}
            />
          </BurgerWrapper>
        </FlexContainer>
      </NavBar>
      <CollapseMenu 
        navbarState={props.navbarState}
        handleNavbar={props.handleNavbar}
      />
      </div>
    </>
  );
};

export default Navbar;

const NavBar = styled(animated.nav)`
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  background:#fff;
  z-index: 9999;
  padding:15px 0px;
`;

const FlexContainer = styled.div`
  max-width: 120rem;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
`;

const NavLinks = styled(animated.ul)`
  justify-self: end;
  display: inline-flexbox;
  list-style-type: none;
  margin: auto 0;
 
  

  & a {
    font-weight:700;
    margin-left:36px;
    text-decoration: none;
    cursor: pointer;

    & svg {
      width: 12px;
      position: absolute;
      height: 12px;
      top: 4px;
      left: 60px;
    }

    @media (max-width: 769px) {
      display: none;
    }
  }
`;

const BurgerWrapper = styled.div`
  margin: auto 0;

  @media (min-width: 769px) {
    display: none;
  }
`;

// ============================



const DropDownContent = styled.div`
  display: none;
  padding: 0rem 1rem 0rem 1rem;
  position:absolute;
  width:200%;
  background-color: white;
  padding-top:43px;
left:19px;
  & li {
    transition: all 300ms linear 0s;
    margin-bottom:20px;
  }
  & a {
    margin-left:0px;
  }
 
`;
const StyledLi = styled.li``;
const DropDownLi = styled(StyledLi)`
  & li {
    list-style-type: none;
  }
  &:hover {
    background-color: white;
    position:relative;
  }
  &:hover ${DropDownContent} {
    display: flex;
    @media (max-width: 768px) {
      display: column;
    }
  }
`;
const CardWrapper = styled.div`
  display: block @media (max-width: 768px) {
    flex-direction: column;
  }
`;