import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import MenuIcon from '@mui/icons-material/Menu';

export default function Header({
	handleScroll = () => {}
}) {
	return (
		
	<header className="custom-container">
		<Grid container>
		    <Grid  variant="outlined" item md={2} xl={2} xs={2} className="header-logo">
			<div onClick={() => handleScroll('home')} style={{ cursor: 'pointer' }}>
				<img
					src="../Images/DoriLogo_black_svg.svg"
					alt="Dori-Logo"
					width="109px"
					height="35px"
				></img>
			</div>
			</Grid>
			<Grid  variant="outlined" item md={10} xl={10} xs={10}>
			<Link to="" className="mobile-menu-icon"><MenuIcon /></Link>
			<ul className="desktop-menu">
				<li>
					<div onClick={() => handleScroll('platform')} className="text-blue-dark small-heading link-animation">Platform</div>
				</li>
				<li>
					<div onClick={() => handleScroll('solutions')} className="text-blue-dark small-heading link-animation">Solutions</div>
				</li>
				<li>
					<div onClick={() => handleScroll('about')} className="text-blue-dark small-heading link-animation">About Dori</div>
				</li>
				{/* <li>
					<div to="/careers" className="text-blue-dark small-heading link-animation">Careers</div>
				</li> */}
				{/* <li>
					<div to="" className="text-blue-dark small-heading link-animation">Resources</div>
				</li> */}
				<li>
					<div onClick={() => handleScroll('contact')} className="text-blue-dark small-heading link-animation">Contact Us</div>
				</li>
			</ul>
			</Grid>
			</Grid>
			
		</header>
	);
}
