import React from 'react';

const Contact = () => {
  return (
    <>
      <div className="container">

        <div className="row">
          <div className="col">
            <div className="section-dark-header">
              <h2 style={{ paddingTop: '80px' }}>CONTACT DORI</h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12" style={{ paddingBottom: '30px' }}>
          </div>
        </div>

        <div className="row wow fadeInUp">

          <div className="col-md-6" style={{ textAlign: 'left' }}>
            <div className="contact-about">
              <h3>Locations</h3>
              <p>
                <h5>US - Headquarters</h5>
                <br />
                <i className="fa fa-map-marker" style={{ color: 'black', paddingRight: '20px'}}>&nbsp;&nbsp;Address: 565 Canyon Drive, Solana Beach, CA 92075</i>
                <br />
                <i className="fa fa-envelope" style={{ color: 'black', paddingRight: '20px' }}></i><a href="mailto:admin@doriinc.com">
                  admin@doriinc.com</a>
              </p>

              <div className="row">
                <div className="col" style={{ paddingTop: '10px', paddingBottom: 0 }}>
                  <div className="section-dark-header">
                    <span className="section-divider" style={{ width: '80%', textAlign: 'center' }}></span>
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col" style={{ paddingTop: '10px', paddingBottom: 0 }}>
                  <div className="section-dark-header">
                    <span className="section-divider" style={{ width: '80%', textAlign: 'center' }}></span>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="col-md-6">
            <div className="box">
              <p style={{ color: 'var(--color-dori-blue)' }}>To contact us, please email us at admin@doriinc.com. We'd be happy to
                chat with you.</p>

            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-12" style={{ paddingBottom: '100px' }}>
          </div>
        </div>

      </div>

      <footer id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-10 text-lg-left text-center">
              <div className="copyright">
                &copy; 2023 Copyright <strong>Dori Incorporated</strong>. All Rights Reserved.
                &nbsp; &nbsp; ∞ &nbsp; &nbsp; <a
                  href="https://app.termly.io/document/terms-of-use-for-saas/b7cef4c9-c3dc-4352-9e78-cd8f1705f7cd"
                  target="_blank" style={{ color: '#ccc' }}>Terms of Use</a>
                &nbsp; &nbsp; ∞ &nbsp; &nbsp; <a href="https://docsend.com/view/np2ew98" target="_blank"
                  style={{ color: '#ccc' }}>Privacy Policy</a>
              </div>
            </div>
            <div className="col-md-2">
              <div className="social-links" style={{ textAlign: 'center' }}>
                <div className="row">
                  <div className="col-4">
                  </div>
                  <div className="col-4">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Contact;