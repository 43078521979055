import React from 'react';
import ButtonGetStartedGray from "./img/button_get_started_gray.png";
import DoriStackWhite from "./img/dori_arch_stack.svg";
import RapidDevWhite from "./img/rapid_development.png";
import FullStackPlatformWhite from "./img/full_stack_platform.png";
import NeuralNetworkWhite from "./img/neural_network.svg";

export default function Platform({
  handleScroll = () => {}
}) {
  return(
    <>
    <div className="intro-text">
        <div className="container">

        <div className="row">
          <div className="col-12" style={{ paddingBottom: '100px' }}>
          </div>
        </div>

        <div className="row wow fadeIn">
          <div className="col-md-12">
            <div className="row">
            <div className="col-md-7">
                <h1>AI OPS WITH DORI</h1>
                <h5>Accelerate Solution Development</h5>
                <br/>
                <p style={{ margin: 0, textAlign: 'left' }}>Building AI applications and systems requires a unique
                combination of expertise, tools, and infrastructure. Dori provides a complete end-to-end platform that
                enables robust dataset/ML model creation, streamlines development, and accelerates time-to-market.</p>
                <div className="row">
                  <div className="col-12" style={{ paddingBottom: '100px' }}>
                  </div>
                </div>
                <p style={{ margin: 0, textAlign: 'center' }}>
                  <button onClick={() => handleScroll('contact')}>
                    <img src={ButtonGetStartedGray} height="30px" alt="Dori AI Platform"/>
                   </button>
                   <br/><br/><br/><br/>
                </p>
            </div>
              <div className="col-md-5" style={{ textAlign: 'center' }}>
                  <img align="center" src={DoriStackWhite} width="90%" style={{ marginLeft: 0 }} alt="Dori AI Platform"/><br/>
              </div>
            </div>
            <br/>

            <div className="row">
              <div className="col-12" style={{ paddingBottom: '50px' }}>
              </div>
            </div>

            <div className="row wow fadeIn">
              <div className="col-md-4" style={{ marginBottom: '40px' }}>
                <div className="row" style={{ marginLeft: 0, height: '150px' }}>
                  <div className="col-12" style={{ alignSelf: 'center', textAlign: 'center' }}>
                      <img align="center" src={RapidDevWhite} height="80px" style={{ marginBottom: '20px' }} alt="Dori AI Platform"/>
                  </div>
                </div>
                <div className="row" style={{ marginLeft: 0 }}>
                  <div className="col-12">
                    <div className="section-header" align="center">
                      <h4 className="section-title" style={{ margin: 0, textAlign: 'center' }}>RAPID LOW-CODE DEVELOPMENT</h4>
                    </div>
                    <br/>
                    <p className="section-description" style={{ marginLeft: 0, textAlign: 'center' }}>
                    Made for application developers; intuitive for data scientists. Unify development efforts across
                    multiple stakeholders.</p>
                  </div>
                </div>
              </div>

              <div className="col-md-4" style={{ marginBottom: '40px' }}>
                <div className="row" style={{ marginLeft: 0, height: '150px' }}>
                  <div className="col-12" style={{ alignSelf: 'center', textAlign: 'center' }}>
                      <img align="center" src={FullStackPlatformWhite} height="80px" style={{ marginBottom: '20px' }} alt="Dori AI Platform"/><br/>
                  </div>
                </div>
                <div className="row" style={{ marginLeft: 0 }}>
                  <div className="col-12">
                    <div className="section-header" align="center">
                      <h4 className="section-title" style={{ margin: 0, textAlign: 'center' }} >FULL STACK PLATFORM</h4>
                    </div>
                    <br/>
                    <p className="section-description" style={{ marginLeft: 0, textAlign: 'center' }}>
                    Fully integrated workflow to build, train, deploy, and monitor AI in your real-world systems
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4" style={{ marginBottom: '40px' }}>
                <div className="row" style={{ marginLeft: 0, height: '150px' }}>
                  <div className="col-12" style={{ alignSelf: 'center', textAlign: 'center' }}>
                      <img align="center" src={NeuralNetworkWhite} height="80px" style={{ marginBottom: '20px' }} alt="Dori AI Platform"/><br/>
                  </div>
                </div>
                <div className="row" style={{ marginLeft: 0 }}>
                  <div className="col-12">
                    <div className="section-header" align="center">
                      <h4 className="section-title" style={{ margin: 0, textAlign: 'center' }} >DATASET & ML MODEL CREATION</h4>
                    </div>
                    <br/>
                    <p className="section-description" style={{ marginLeft: 0, textAlign: 'center' }}>
                    Robust tools to create/enrich datasets and build custom ML models across various use cases.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12" style={{ paddingBottom: '100px' }}>
          </div>
        </div>

      </div>
    </div>
    </>
  );
}
