import React, { Component, Fragment, useRef } from "react";

import Navbar from "./navbar/Navbar";
import Footer from "./SinglePage/Footer";
import { Routes, Route } from "react-router-dom";
import Home from "./SinglePage/Home";
import Platform from "./SinglePage/Platform";
import About from "./SinglePage/About";
import Solutions from "./SinglePage/Solutions";
import Contact from "./SinglePage/Contact";
import Retail from "../pages/Retail";
import Careers from "../pages/Careers";
import DataScientist from "../pages/DataScientist";
import PythonDeveloper from "../pages/PythonDeveloper";
import ProductManager from "../pages/ProductManager";
import FrontEndDeveloper from "../pages/FrontEndDeveloper";
import UIDeveloper from "../pages/UIDeveloper";
import TechnicalContentMarketingSpecialist from "../pages/TechnicalContentMarketingSpecialist";
import DevOpsEngineer from "../pages/DevOpsEngineer";
import SeniorSoftwarEengineer from "../pages/SeniorSoftwarEengineer";
import SrFullStackDeveloperSystemIntegrationDevOps from "../pages/SrFullStackDeveloperSystemIntegrationDevOps";
import Manufacturing from "../pages/Manufacturing";
import Logistics from "../pages/Logistics";
import SecurityAndSafety from "../pages/SecurityAndSafety";
import PageNotFound from "../pages/404-error";
import './global.css';
import Thankyou from "../pages/Thankyou";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsandConditions from "../pages/TermsandConditions";
import GlobalStyle from "../styles/Global";
import Header from "./Header";

const Layout = () => {
	const homeRef = useRef(null);
	const platformRef = useRef(null);
	const solutionRef = useRef(null);
	const aboutRef = useRef(null);
	const contactRef = useRef(null);

	const handleScroll = (section) => {
		if (section === 'home') {
			homeRef.current.scrollIntoView();
		} else if (section === 'platform') {
			platformRef.current.scrollIntoView();
		} else if (section === 'solutions') {
			solutionRef.current.scrollIntoView();
		} else if (section === 'about') {
			aboutRef.current.scrollIntoView();
		} else if (section === 'contact') {
			contactRef.current.scrollIntoView();
		} else homeRef.current.scrollIntoView();
	}
	
	//   render() {
		return (
		  <>
		  	<div className="headerContainer" >
				<Header handleScroll={handleScroll} />
			</div>
		  	<div style={{ marginTop: '70px' }} ref={homeRef}><Home handleScroll={handleScroll} /></div>
		  	<div style={{ marginTop: '70px' }} ref={platformRef}><Platform handleScroll={handleScroll} /></div>
		  	<div style={{ marginTop: '70px' }} ref={solutionRef}><Solutions handleScroll={handleScroll} /></div>
		  	<div style={{ paddingTop: '70px' }} ref={aboutRef}><About handleScroll={handleScroll}  /></div>
		  	<div style={{ marginTop: '70px' }} ref={contactRef}><Contact handleScroll={handleScroll} /></div>
		  </>
		);
	//   }
}
export default Layout;