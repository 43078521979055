import React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";
import {NavLink} from 'react-router-dom'
import { useSpring, animated } from "react-spring";

const CollapseMenu = props => {
  const { open } = useSpring({ open: props.navbarState ? 0 : 1 });

  if (props.navbarState === true) {
    return (
      <CollapseWrapper
        style={{
          transform: open
            .interpolate({
              range: [0, 0.2, 0.3, 1],
              output: [0, 0, 0, -10]
            })
            .interpolate(openValue => `translate3d(0, ${openValue}px, 0`)
        }}
      >
        <NavLinks>
          <ul>
            <li><NavLink to="/platform" className="text-blue-dark small-heading link-animation" onClick={props.handleNavbar} >Platform</NavLink></li>
            <DropDownLi><Link  className="text-blue-dark small-heading link-animation"  to="#"  >Solutions<svg viewBox="0 0 384 512"><path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/></svg></Link>
            <DropDownContent>
            <ul>
              
              <li>
      <NavLink to="solutions/manufacturing" className="text-blue-dark small-heading link-animation" onClick={props.handleNavbar}>Manufacturing</NavLink>
    </li>
    <li>
      <NavLink to="solutions/retail" className="text-blue-dark small-heading link-animation" onClick={props.handleNavbar}>Retail</NavLink>
    </li>
    <li>
      <NavLink to="solutions/logistics" className="text-blue-dark small-heading link-animation" onClick={props.handleNavbar}>Logistics</NavLink>
    </li>
    <li>
      <NavLink to="solutions/securitysafety" className="text-blue-dark small-heading link-animation" onClick={props.handleNavbar}>Security & Safety</NavLink>
    </li>

            </ul>
    </DropDownContent></DropDownLi>


    <li>
					<NavLink to="/about" className="text-blue-dark small-heading link-animation" onClick={props.handleNavbar}>About Dori</NavLink>
				</li>
				<li>
					<NavLink to="/careers" className="text-blue-dark small-heading link-animation" onClick={props.handleNavbar}>Careers</NavLink>
				</li>
			{/*	<li>
					<Link to="" className="text-blue-dark small-heading link-animation" >Resources</Link>
      </li>*/}
				<li>
					<NavLink to="/contact" className="text-blue-dark small-heading link-animation" onClick={props.handleNavbar}>Contact Us</NavLink>
				</li>
    
          </ul>
        </NavLinks>
      </CollapseWrapper>
    );
  }
  return null;
};

export default CollapseMenu;

const CollapseWrapper = styled(animated.div)`
  background: #fff;
  position: relative;
  margin-top: 0rem;
  left: 0;
  right: 0;
  z-index:9999;
  
  @media (min-width: 769px) {
    display:none;
  }
`;

const NavLinks= styled.ul`
  list-style-type: none;
  padding: 1rem 1rem 2rem 0.5rem;

  & li {
    transition: all 300ms linear 0s;
    list-style-type: none;
    padding-bottom:15px;
  }

  & a {
   font-weight:700;
    cursor: pointer;

    
  }
  & svg {
    width: 12px;
    position: absolute;
    height: 12px;
    top: 4px;
    left: 65px;
  }
`;

const DropDownContent = styled.div`
  display: none;
  list-style-type: none;
  padding: 1rem 0rem 0rem 0rem;
  & ul {
    
    list-style:none;
  }

  & li {
    transition: all 300ms linear 0s;
    list-style-type: none;
  }
  & a {
    cursor: pointer;
    font-weight:700;
}
`;
const StyledLi = styled.li`
`;
const DropDownLi = styled(StyledLi)`
  &:hover {
    background-color: White;
  }
  &:hover ${DropDownContent} {
    display: flex;
    @media (max-width: 768px) {
    display: column;
  }
  }
`;
const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

