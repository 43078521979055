import React from 'react';
import './css/style.css';
import ButtonLearnMoreGray from "./img/button_learn_more_gray.png";
import DoriHighlevelFlow from "./img/dori_high_level_flow.png";
import AutomatedVisualAnalysisBlue from "./img/icon/automated_visual_analysis_blue.png";
import BuildVisulaModels from "./img/build_visual_models.png";
import VisualTaskAutomation from "./img/icon/visual_task_automation.png";

export default function Home({
  handleScroll = () => {}
}) {
  return (
    <>
      <div className="intro-text">
        <div className="container">
          <div className="row">
            <div className="col-md-1" align="left">
            </div>
            <div className="col" align="center">
              <h1 className="section-title">Full Stack AI Ops Platform</h1>
              <div className="intro-subtitle" style={{ color: 'gray' }}>UNLOCK THE POWER OF ARTIFICIAL INTELLIGENCE</div>
            </div>
            <div className="col-md-1" align="left">
            </div>
          </div>

          <div className="row">
            <div className="col-12" style={{ paddingBottom: '50px' }}>
            </div>
          </div>

          <div className="row">
            <div className="col" align="center">
              <br />
              <p>Dori is a low code/no-code deep learning platform to rapidly build and launch enterprise AI
                applications.</p>

              <div className="row">
                <div className="col-12" style={{ paddingBottom: '50px' }}>
                </div>
              </div>

              <p align="center">
                <button onClick={() => handleScroll('platform')}>
                  <img
                    src={ButtonLearnMoreGray}
                    height="30px"
                    alt="Dori AI Platform"
                  />
                </button><br /><br /><br /><br />
              </p>
            </div>
          </div>
        </div>
      </div>


      {/* ///////////////////////////// */}



      <div className="container">

        <div className="row">
          <div className="col-12" style={{ paddingBottom: '60px' }}>
          </div>
        </div>

        <div className="row wow fadeIn">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-5" style={{ paddingBottom: '40px' }}>
              <h2 style={{ color: 'black' }}>THE DORI ADVANTAGE</h2>
              <h5>Transform Visual Data Into Actionable Insights</h5>
              <br/>
              <p style={{ margin: 0, textAlign: 'left' }}>Dori provides a platform to allow you to utilize and monetize your
              visual data by creating actionable insights from your unstructured image and video data. The Dori Platform
              enables subject matter experts, developers, and data scientists to leverage state of the art innovations in deep
              learning to rapidly build robust applications.</p>
            </div>
              <div className="col-md-7" style={{ textAlign: 'center' }}>
                  <img align="center" src={DoriHighlevelFlow} width="100%" alt="Dori AI Platform" /><br/>
              </div>
            </div>

            <div className="col-12" style={{ paddingBottom: '50px' }}>
            </div>

            <div className="row wow fadeIn">
              <div className="col-md-4" style={{ paddingBottom: '40px' }}>
                <div className="row" style={{ marginLeft: 0, height: '150px' }}>
                  <div className="col-12" style={{ alignSelf: 'center', textAlign: 'center' }}>
                      <img align="center" src={AutomatedVisualAnalysisBlue} width="70%" style={{ paddingBottom: '20px' }} alt="Dori AI Platform" /><br/>
                  </div>
                </div>
                <div className="row" style={{ marginLeft: 0 }}>
                    <div className="col-12">
                      <div className="section-header" align="left">
                      <h4 className="section-title" style={{ margin: 0, textAlign: 'center' }}>AUTOMATE DATA ANALYSIS</h4>
                    </div>
                    <br/>
                    <p className="section-description" style={{ marginLeft: 0, textAlign: 'center' }}>
                    Automatically curate/select relevant training data and preprocessing through Dori's pretrained visual
                    analyzers to speed up annotation and training time.</p>
                    <br/>
                </div>
                </div>
              </div>

              <div className="col-md-4" style={{ marginBottom: '40px' }}>
                <div className="row" style={{ marginLeft: 0, height: '150px' }}>
                  <div className="col-12" style={{ alignSelf: 'center', textAlign: 'center' }}>
                      <img align="center" src={BuildVisulaModels} width="40%" style={{ marginBottom: 0 }} alt="Dori AI Platform" /><br/>
                  </div>
                </div>
                <div className="row" style={{ marginLeft: 0 }}>
                  <div className="col-12">
                    <div className="section-header" align="center">
                      <h4 className="section-title" style={{ margin: 0, textAlign: 'center' }} >BUILD ROBUST AI MODELS</h4>
                    </div>
                    <br/>
                  <p className="section-description" style={{ marginLeft: 0, textAlign: 'center' }}>
                  Customize AI models with Dori Vision to build proprietary visual grammars that
                  enable intelligent automation to your existing enterprise workflows.
                  </p>
                    <br/>
                  </div>
                </div>
              </div>

              <div className="col-md-4" style={{ marginBottom: '40px' }}>
                <div className="row" style={{ marginLeft: 0, height: '150px' }}>
                  <div className="col-12" style={{ alignSelf: 'center', textAlign: 'center' }}>
                      <img align="center" src={VisualTaskAutomation} width="80%" style={{ marginBottom: 0 }} alt="Dori AI Platform" /><br/>
                  </div>
                </div>
                <div className="row" style={{ marginLeft: 0 }}>
                  <div className="col-12">
                  <div className="section-header" align="left">
                    <h4 className="section-title" style={{ margin: 0, textAlign: 'center' }}>ENTERPRISE APPLICATIONS</h4>
                    </div>
                    <br/>
                  <p className="section-description" style={{ marginLeft: 0, textAlign: 'center' }}>
                  Seamlessly optimize and deploy solutions on any edge, on-premise or cloud platform while continuously
                  monitoring for anomalies.
                  </p>
                    <br/>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12" style={{ paddingBottom: '60px' }}>
          </div>
        </div>
      </div>
    </>
  );
}
