import React from 'react';
import BrainBlue from "./img/brain_blue.png";
import ButtonApplyNow from "./img/button_apply_now_gray.png";

const About = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="section-dark-header">
              <h1>ABOUT DORI</h1>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12" style={{ paddingBottom: '30px' }}>
          </div>
        </div>


        <div className="row" id="margin-bottom:30px;">
          <div className="col-md-6">
            <h2>Dori AI at a Glance</h2>
            <p className="section-description" id="padding:0px;text-align:left">
              Dori AI is a full stack end-to-end AI development platform to help
              enterprises
              rapidly build and deploy AI solutions. The Dori Vision platform was built for transparency and scalable deployments across edge, on-prem, and cloud infrastructure.</p>

            <p id="margin-bottom:10px;text-align:left">The platform provides a robust suite to create intelligent
              applications:</p>
            <ul>
              <li>A unified platform that accelerates the development of AI applications for edge
                device,
                server and/or cloud deployments.</li>
              <li>A robust set of tools for data/image management and enrichment, model experimentation and creation,
                and
                analytics/monitoring to ensure adaptability.</li>
              <li>A set of tools/infrastructure that enables enterprises to manage the lifecycle of models and tie the
                results from one stage to the next (annotation, training, benchmarking, deployment, and monitoring).
              </li>
            </ul>

            <p id="margin-bottom:10px;text-align:left">We partner with industry and ecosystem leaders to enable
              developers with the latest AI/ML technologies. Contact us to find out more about how we can help you with
              your next solution.</p>

          </div>
          <div className="col-md-1">

            <div className="row">
              <div className="col-12" id="padding-bottom:30px">
              </div>
            </div>

          </div>
          <div className="col-md-5">
            <h2>Who We Are</h2>
            <p id="padding:0px;text-align:left">
              We are a group of AI/ML and computer vision experts, system engineers, entrepreneurs, data scientists,
              futurists, and more. Building complex systems that help improve the lives of millions of people gets us
              energized.</p>
            <p>At Dori, we set out to simplify the challenge of building and launching artificial intelligence
              applications. We bring together a set of unified frameworks, tools, and infrastructure as well as deep
              systems
              knowledge and expertise to build scalable solutions. The Dori deep learning platform empowers enterprises
              to
              build and launch customized intelligent AI applications and automated systems.</p>
            <p id="padding:0px;text-align:center">
              <img src={BrainBlue} width="70%" alt="Dori AI Platform" />
            </p>
          </div>
        </div>

      </div>


      <div className="row" style={{ marginTop: 0, paddingBottom: '20px' }}>
        <div className="col-md-1">
        </div>
        <div className="col-md-10">
          <p className="section-description" style={{ textAlign: 'center' }}>
            <br /><br /><br /><br />
            Become part of our team or email us directly at &nbsp;
            <a href="mailto:careers@dori.ai">admin@doriinc.com</a>&nbsp;
            and let us know how you can help the team.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col" style={{ paddingTop: '50px', paddingBottom: '100px' }}>
          <div className="section-dark-header">
            <span className="section-divider" style={{ width: '80%', textAlign: 'center' }}></span>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;